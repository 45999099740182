import React, {useState} from "react";
import JobDescription from "../JobDescription/JobDescription";
import recJobsArray from "../../recJobData.js";
import projectData from "../../projectData";
import "./Resume.css"
import devResume from "../../assets/andygs-dev-resume.pdf";
import recResume from "../../assets/andygs-rec-resume.pdf";

export default function Resume({width}) {
    
    const [resumePage, setResumePage] = useState("dev");

    const firstThreeProjects = projectData.map( (project, index) => {
        if(index <= 2) {
            return(
                <tr key={crypto.randomUUID()}>
                    <td>
                        <p className="projectTitle">{project.title}</p>
                        <p className="projectSubtitle">{project.subtitle}</p>
                    </td>
                    <td><p className="projectDescription">{project.description}</p></td>
                    <td><p className="projectTech">{project.tech}</p></td>
                    <td><p className="projectLearnings">{project.learnings}</p></td>
                </tr>
            )
        } else {
            return("")
        }
    })

    const projectTable = () => {
        
        return (
            <>
                {width > 600 ? ( <table className="projectTable">
                <tbody>
                    <tr>
                        <th className="columnOne">Project</th>
                        <th className="columnTwo">Description</th>
                        <th className="columnThree">Tech</th>
                        <th className="columnFour">Learnings</th>
                    </tr>
                    {firstThreeProjects}
                </tbody>
            </table>) : "Screen size too small - please switch to landscape mode, enlarge your browser window, or download my resume to view the project table"}
            </>
        )
      }

    const devJobDescriptions = () => {
        return(
            <div className="devResumeContainer">
                <p>Entry-level developer, veteran, leader, and technologist focused on individual excellence while impacting those around me.  Previously a world-class technical recruiter, I was impacted by Meta’s company-wide layoffs and decided to turn my deep passion for technology into a skill.  After attending UW/EdX’s full-stack bootcamp, I’m now pursuing developer opportunities that provide real-world experience while allowing me to continue my path of growth and development.</p>

                <ul className="devSkillsList">
                    <li><b>Technical Skills:</b> SDLC, Front-end Development, Server-side Development, Databases (ERD, Modeling, Coding)</li>
                    <li><b>Languages:</b> HTML, CSS, JavaScript</li>
                    <li><b>Frameworks:</b> Express.js, Node.js, React, jQuery</li>
                    <li><b>DBs:</b> MySQL, MongoDB</li>
                </ul>

                <div className="companyDetails">
                    <span className="companyName">University of Washington/EdX Full-Stack Developer Bootcamp</span><span className="datesOfEmployment">Dec 2022 - Mar 2023</span>
                </div>
                <ul className="jobDuties">
                    <li>HW/Challenge grade – A+/100.27%</li>
                    <li>20-hours per week curriculum, 30-40+ hours per week of self-study</li>
                    <li>Modules: HTML, CSS, and Git, Advanced CSS, JavaScript, Web APIs, Third-Party and Server-Side APIs, Node.js, Express.js, SQL, NoSQL, Object-Relational Mapping (ORM), Model-View-Controller (MVC), React, Full-stack MERN application, State (also object-oriented programming, computer science for JavaScript, PWAs)</li>
                </ul>
                <p style={{fontWeight: "bold"}}>PROJECTS</p>
                {projectTable()}
            </div>
        )
    }

    const recruitingJobDescriptions = recJobsArray.map(item => {
        return(
            <JobDescription 
                key = {crypto.randomUUID()}
                title = {item.title}
                logo = {item.logo}
                dates = {item.dates}
                roles = {item.roles}
            />
        )
    })

    const handleClick = (event) => {
        setResumePage(event.target.nextSibling.id);
    }

    return(
        <div className="jobContainer">
            <div className="jobNavBar">
                <ul className="jobNavMenu">
                    <li><input type="radio" name="jobNavItem" className="jobNavItem" onClick={handleClick} defaultChecked></input><span id="dev" style={resumePage === "dev" ? {fontWeight: "bold"} : {fontWeight: "normal"}}>Dev Resume</span></li>
                    <li><input type="radio" name="jobNavItem" className="jobNavItem" onClick={handleClick}></input><span id="rec" style={resumePage === "rec" ? {fontWeight: "bold"} : {fontWeight: "normal"}}>Recruiter Resume</span></li>
                </ul>
                {/* a tag href is pointing to the file to download which is imported as devResume/recResume, while the download attribute gives the file a custom name
                both are based on the boolean value of which resume page is displayed */}
                <a className="resumeLink" href={resumePage === "dev" ? devResume : recResume} target="none" download={resumePage === "dev" ? "Andy_Gaudy-Fullstack_Dev_Resume.pdf" : "Andy_Gaudy-Recruiter-Resume.pdf"}><p>Download my {resumePage === "dev" ? "developer" : "recruiter"} resume</p></a>
            </div>
            {resumePage === "dev" ? devJobDescriptions() : recruitingJobDescriptions}
        </div>
    )
}