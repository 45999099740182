import React, {useState, useEffect} from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Resume from "./components/Resume/Resume";
import HorizProject from "./components/HorizProject/HorizProject";
import VertProject from "./components/VertProject/VertProject";
import profilePic from "./assets/profile-pic.jpg"
import './App.css';

function App() {

  let [page, setPage] = useState("about-me");
  let [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, [])

  function renderPage(){
    if(page === "about-me"){
      return(
        <div className="aboutMeContainer">
          <div className="profilePicture">
            <img src={profilePic} alt="my face with a VR headset" />
          </div>
          <div className="aboutMeContent">
            <div className="aboutMeHeader">
              <h2 style={{display: "inline-block"}}>About Me</h2>
              <span style={{textAlign: "center"}}>
                <a href="https://www.github.com/maximusDecimalusMeridius"><p style={{display: "inline-block", fontSize: "14px"}}>GitHub</p></a> | <a href="https://www.linkedin.com/in/the-recruiter-that-is-technical"><p style={{display: "inline-block", fontSize: "14px"}}>LinkedIn</p></a><br /><a href="mailto:andygg_dev@yahoo.com"><p style={{display: "inline-block", fontSize: "14px"}}>andygg_dev@yahoo.com</p></a>
              </span>
            </div>
            <div className="linebreak"></div>
            <div>
              <p><b>Technical Skills:</b> SDLC, Front-end Development, Server-side Development, Databases (ERD, Modeling, Coding)</p>
              <p><b>Languages:</b> HTML, CSS, Javascript</p>
              <p><b>Frameworks:</b> Express.js, Node.js, React, jQuery</p>
              <p><b>DBs:</b> MySQL, MongoDB</p>
            </div>
            <br/>
            <p>
            A veteran, leader, and technologist focused on individual excellence while impacting those around me.  Previously a world-class technical recruiter, I was impacted by Meta’s company-wide layoffs and decided to turn my deep passion for technology into a skill.  After attending UW/EdX’s full-stack bootcamp, I’m now pursuing developer opportunities that will provide real-world experience while allowing me to continue my path of growth and development. 
            </p>
            <br />
            <h3>Background</h3>
            <div className="linebreak"></div>
            <ul className="backgroundList">
              <li className="backgroundItem">Full-stack Developer (HTML, CSS, JS, React, MySQL, MongoDB) - Entry Level</li>
              <li className="backgroundItem">Staff-level Tech Recruiter - 11 years</li>
              <li className="backgroundItem">Global/Remote Project Management - 3 years</li>
              <li className="backgroundItem">Program Management - 6 years</li>
              <li className="backgroundItem">U.S. Marine - 6 years</li>
            </ul>
          </div>
        </div>
      )
    } else if (page === "projects" && width > 800) {
      return(
        <HorizProject />
      )
    } else if (page === "projects" && width < 800) {
      return(
        <VertProject />
      )
    } else if (page === "resume") {
      return(
        <Resume 
          width={width}/>
      )
    }
  }

  return (
    <div className="App">
      <Header page={page} setPage={setPage}/>
        {renderPage()}
      <Footer />
    </div>
  );
}

export default App;
