/* eslint-disable import/no-anonymous-default-export */
import roomeaseLogo from "./assets/thumbs/roomease.png";
import superheroLogo from "./assets/thumbs/superhero-game.png";
import quizgameLogo from "./assets/thumbs/quiz-game.png";
import weatherLogo from "./assets/thumbs/weather-tracker.png";
import blogLogo from "./assets/thumbs/blog-app.png";
import gamerpadLogo from "./assets/thumbs/gamerpad.png"

export default[
    {
        title: "Git-Good",
        subtitle: "A Hodgepodge Card Game!",
        image: superheroLogo,
        description: "Superhero multi-track card game with randomization and infant AI",
        tech: "HTML, CSS, JS, Materialize CSS & jQuery",
        learnings: "API fetch requests, local storage, game design, event listeners & animations",
        liveURL: "https://maximusdecimalusmeridius.github.io/git-good/arena/",
        githubURL: "https://www.github.com/maximusDecimalusMeridius/git-good"
    },
    {
        title: "Roomease",
        subtitle: "Housemate Companion App",
        image: roomeaseLogo,
        description: "Full-stack application to manage roommates, events, tasks, and 'You-Owe-Mes'",
        tech: "HTML, CSS, JS, Express, Node.js, MySQL",
        learnings: "ERD development and implementation, signup/login auth, npm packages, project management",
        githubURL: "https://www.github.com/maximusDecimalusMeridius/roomease"
    },
    {
        title: "gamerPad",
        subtitle: "Social Platform for Gamers",
        image: gamerpadLogo,
        description: "Full-stack social platform where users can add friends, games, notes, accounts, and join communities!",
        tech: "HTML, CSS, JS, React, Express, Node.js, MySQL",
        learnings: "Mobile-first design, multi-repo setup, learning to manage state and data across the application, client/server relationships",
        githubURL: "https://www.github.com/maximusDecimalusMeridius/gamerpad"
    },
    {
        title: "Code Quiz Game",
        image: quizgameLogo,
        description: "Quiz game with random question selection, text coloring, and local storage",
        tech: "HTML, CSS, JS",
        learnings: "localStorage, JS event listeners, managing data in arrays",
        liveURL: "https://maximusdecimalusmeridius.github.io/my-quiz-game",
        githubURL: "https://www.github.com/maximusDecimalusMeridius/my-quiz-game"
    },
    {
        title: "Weather Tracker",
        image: weatherLogo,
        description: "A single-page weather tracking app that uses API fetch requests",
        tech: "HTML, CSS, JS, Third-Party APIs",
        learnings: "Mobile-first design, API fetch requests, page layout & formatting",
        liveURL: "https://maximusdecimalusmeridius.github.io/weather-tracker",
        githubURL: "https://www.github.com/maximusDecimalusMeridius/weather-tracker"
    },
    {
        title: "Blog App",
        image: blogLogo,
        description: "Full-stack blog application that allows users to create blog posts and comment on others' posts",
        tech: "HTML, CSS, JS, Express, Node.js, MySQL",
        learnings: "ERD design and joins, MVC model & handlebars, sessions, sign-up and login auth",
        liveURL: "https://blogzzz.herokuapp.com",
        githubURL: "https://www.github.com/maximusDecimalusMeridius/blog-app"
    }
]